<template>
  <span class="glyphicon" :class="'glyphicon-' + id" />
</template>
<script>
export default {
  components: {},
  name: "icon",
  props: {
    id: {
      type: String,
      default() {
        return "home";
      }
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
@import @/assets/sass/glyphicons/glyphicon.scss
</style>
